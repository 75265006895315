"use client";
import React, { useState } from "react";
import Head from "next/head";
import Image from "next/image";
import { Inter } from "next/font/google";
import styles from "@/styles/Home.module.css";
import NavBarLanding from "@/components/NavBarLanding";
import Footer from "@/components/Footer";
import { userIsLoggedInLanding } from "@/utils/user";
import Link from "next/link";
import { SignFrom } from "@/components/Sign";
import { InView } from "react-intersection-observer";
import InViewDiv from "./components/InViewDiv";
import RotatingText from "./RotatingText";

const inter = Inter({ subsets: ["latin"] });

export default function Home() {
  const [signValue, setSignValue] = useState<string>("signup");
  const [toggleSignForm, setToggleSignForm] = useState<boolean>(false);

  return (
    <>
      <Head>
        <title>Eurekia !</title>
        <meta name="description" content="L'assitant IA fait pour vous !" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className=" items-center justify-center flex-col bg-main overflow-x-hidden sm:h-full relative h-auto pt-8 sm:pt-0">
        {toggleSignForm ? (
          <div className="w-full sm:h-full h-screen flex justify-center items-center">
            <div
              onClick={() => setToggleSignForm(false)}
              className="z-50 w-full h-full fixed top-0 left-0 flex justify-center items-center backdrop-blur-md bg-black bg-opacity-10"
            ></div>
            <div className="z-[1000] fixed flex justify-center">
              <SignFrom signValue={signValue} />
            </div>
          </div>
        ) : null}
        <NavBarLanding
          signValue={signValue as string}
          setSignValue={setSignValue}
          toggleSignForm={toggleSignForm}
          setToggleSignForm={setToggleSignForm}
        />
        <section
          className="h-[70%] w-full flex items-center justify-center min-h-screen flex-col"
          id="landing"
        >
          {/* <div className='w-3/5 flex justify-center items-center'>
            <RotatingText />
          </div> */}
          <InViewDiv className=" w-[80%] flex-col lg:flex-row flex  gap-2">
            <div className="w-full flex flex-col gap-8 h-1/2 lg:h-full justify-center">
              <div className="w-full flex flex-col sm:mt-0 justify-center">
                <Image
                  className="w-full sm:w-[60%] flex sm:justify-center"
                  src="/img/landing/logofull.svg"
                  alt="eurekia logo"
                  width={300}
                  height={300}
                />
                <h1 className="font-medium text-3xl lg:text-[2.4vw] leading-tight flex sm:items-start items-center justify-center flex-col text-left">
                  {/* <span>Des besoins différents,</span>
                  <span>un outil IA unique.</span> */}
                  <RotatingText />
                </h1>
              </div>
              <div className="flex sm:justify-start justify-center">
                <button
                  onClick={() => {
                    setToggleSignForm(true);
                    setSignValue("signup");
                  }}
                  className="z-10 border border-[#E17DC2] bg-[#E17DC2] transition hover:bg-[#e08fc8] text-white rounded-full py-1 sm:py-2 pr-3  font-semibold sm:text-xl text-l flex gap-2 justify-center items-center pl-6"
                  data-te-sidenav-link-ref
                >
                  <span>Essai gratuit</span>
                  <svg
                    width={36}
                    height={36}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m9 6 6 6-6 6" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex w-full h-1/2 lg:h-full relative justify-center items-center">
              <Image
                className="w-[800px]"
                src="/img/landing/landing.webp"
                alt="multi IA logo"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 80vw"
                width={1600}
                height={900}
              />
            </div>
          </InViewDiv>
        </section>
        <section className="w-full flex items-start justify-center relative h-[300px] sm:h-[400px] md:h-[600px] lg:h-[800px]">
          <div
            style={{
              background:
                "-webkit-linear-gradient(rgb(255, 255, 255, 0.1), rgb(255, 255, 255)), linear-gradient(rgb(255, 255, 255, 0), rgb(255, 255, 255))",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundAttachment: "fixed",
            }}
            className="flex flex-col w-[100vw] justify-center items-center  absolute md:top-20 overflow-hidden flex-nowrap md:gap-4 gap-20 sm:gap-10 lg:gap-0 text-clip"
          >
            <h2 className="text-[12vw]  font-bold text-center whitespace-nowrap bg-clip-text text-transparent">
              Créer, Partager,
            </h2>
            <h2 className="text-[12vw] text-white2 font-bold text-center whitespace-nowrap -mt-20  bg-clip-text text-transparent">
              Optimiser.
            </h2>
          </div>
        </section>
        <section
          className="w-full flex justify-center relative items-center h-full"
          id="tools"
        >
          <InViewDiv className="lg:w-[80%] w-full flex flex-col lg:flex-row my-10">
            <div className="flex-col flex sm:flex-row w-full lg:w-full justify-center pb-10">
              <div className="flex flex-col w-full h-full justify-center lg:justify-end items-center gap-4">
                <div className="flex justify-start items-center w-[80%] lg:w-full">
                  <p
                    style={{
                      background:
                        "linear-gradient(to right, rgba(139, 222, 218, 1), rgba(67, 173, 208, 0.1))",
                    }}
                    className="py-2 px-8 rounded-full text-white2 text-xl"
                  >
                    Editor IA
                  </p>
                </div>
                <h2 className="text-white2 text-4xl sm:text-5xl lg:text-[4vw] font-bold w-[80%] lg:w-full leading-tight	">
                  Editeur de <br />
                  <span className="text-[#8BDEDA]">texte</span> nouvelle <br />
                  <span className="text-[#43ADD0]">génération</span>
                </h2>
                <div className="flex w-[80%] lg:w-full justify-start">
                  <p className="text-white2 text-2xl lg:w-[75%]">
                    Créez du texte plus rapidement grâce à notre technologie
                    avancée d&apos;intelligence artificielle. Stimulez votre
                    créativité sans limites. Le tout rassemblé dans un éditeur
                    de texte propulsé par les meilleurs modèles d&apos;IA.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-col flex sm:flex-row w-full justify-center">
              <div className="h-full w-full flex justify-center lg:w-full relative">
                <div className="z-10 rounded-3xl w-[80%] h-full lg:w-full relative bg-[#998EE0] bottom-3 left-3 flex items-end shadow-2xl">
                  <Image
                    width={1600}
                    height={900}
                    className="h-full w-full object-cover rounded-3xl shadow-2xl object-right-top"
                    src="/img/landing/editoriaShot.webp"
                    alt="chat editor ia"
                  />
                </div>
                <div className="backdrop-blur-md bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] w-[80%] lg:w-full h-full rounded-3xl absolute top-3 right-3"></div>
              </div>
            </div>
          </InViewDiv>
        </section>
        <section className="w-full flex justify-center relative items-center h-full">
          <InViewDiv className="lg:w-[80%] w-full flex flex-col lg:flex-row my-10">
            <div className="flex-col flex sm:flex-row w-full lg:w-full justify-center pb-10">
              <div className="flex flex-col w-full h-full justify-center lg:justify-end items-center gap-4">
                <div className="flex justify-start items-center w-[80%] lg:w-full">
                  <p
                    style={{
                      background:
                        "linear-gradient(to right, rgba(153, 142, 224, 1), rgba(153, 142, 224, 0.1))",
                    }}
                    className="py-2 px-8 rounded-full  text-white2 text-xl"
                  >
                    Chat IA
                  </p>
                </div>
                <h2 className="text-white2 text-4xl sm:text-5xl lg:text-[4vw] font-bold w-[80%] lg:w-full leading-tight	">
                  Chat avec des
                  <br />
                  <span className="text-[#998EE0]">profils</span>
                  <br />
                  <span className="text-[#998EE0]">spécialisés</span>
                  <br />
                  <span className="text-[#de81e3]">& PDF</span>
                </h2>
                <div className="flex w-[80%] lg:w-full justify-start">
                  <p className="text-white2 text-2xl lg:w-[75%]">
                    Communiquez efficacement avec un chat intelligent, adaptable
                    à différents profils et besoins. Discutez et interagissez
                    directement avec vos documents PDF. Transformez vos fichiers
                    statiques en conversations productives.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-col flex sm:flex-row w-full justify-center">
              <div className="h-full w-full flex justify-center lg:w-full relative">
                <div className="z-10 rounded-3xl w-[80%] h-full lg:w-full relative bg-[#998EE0] bottom-3 left-3 flex items-end shadow-2xl">
                  <Image
                    width={1600}
                    height={900}
                    className="h-full w-full object-cover rounded-3xl shadow-2xl object-right-top"
                    src="/img/landing/chatShot.webp"
                    alt="chat editor ia"
                  />
                </div>
                <div className="backdrop-blur-md bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] w-[80%] lg:w-full h-full rounded-3xl absolute top-3 right-3"></div>
              </div>
            </div>
          </InViewDiv>
        </section>
        <section className="w-full flex justify-center relative items-center h-full">
          <InViewDiv className="lg:w-[80%] w-full flex flex-col lg:flex-row  my-10">
            <div className="flex-col flex sm:flex-row w-full lg:w-[80%] justify-center pb-10">
              <div className="flex flex-col w-full h-full justify-center lg:justify-end items-center gap-4">
                <div className="flex justify-start items-center w-[80%] lg:w-full">
                  <p
                    style={{
                      background:
                        "linear-gradient(to right, rgba(225, 125, 194, 1), rgba(225, 125, 194, 0.1))",
                    }}
                    className="py-2 px-8 rounded-full  text-white2 text-xl"
                  >
                    Modèle Création
                  </p>
                </div>
                <h2 className="text-white2 text-4xl sm:text-5xl lg:text-[4vw] font-bold w-[80%] lg:w-full leading-tight	">
                  Prompts & <br />
                  profils <br />
                  <span className="text-[#E17DC2]">personnalisables</span>
                </h2>
                <div className="flex w-[80%] lg:w-full justify-start">
                  <p className="text-white2 text-2xl lg:w-[75%]">
                    Bénéficiez instantanément de nos prompts et profils
                    pré-conçus et optimisés, prêts à l&apos;emploi pour
                    accélérer votre productivité. Vous avez une tâche répétitive
                    ? Personnalisez votre expérience en créant et réutilisant
                    vos propres prompts pour une efficacité inégalée.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-col flex sm:flex-row w-full justify-center">
              <div className="h-full w-full flex justify-center lg:w-[90%] relative">
                <div className="z-10 rounded-3xl w-[80%] h-full lg:w-full relative lg:absolute bg-[#E17DC2] bottom-3 left-3 flex items-end shadow-2xl">
                  <Image
                    width={1600}
                    height={900}
                    className="h-full w-full object-cover  rounded-3xl shadow-2xl "
                    src="/img/landing/prompShot.webp"
                    alt="prompt creation ia"
                  />
                </div>
                <div className="backdrop-blur-md bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] w-[80%] lg:w-full h-full rounded-3xl absolute top-3 right-3"></div>
              </div>
            </div>
          </InViewDiv>
        </section>
        <section className="w-full flex justify-center relative items-center h-full">
          <InViewDiv className="lg:w-[80%] w-full flex flex-col lg:flex-row  my-10">
            <div className="flex-col flex sm:flex-row w-full lg:w-[80%] justify-center pb-10">
              <div className="flex flex-col w-full h-full justify-center lg:justify-end items-center gap-4">
                <div className="flex justify-start items-center w-[80%] lg:w-full">
                  <p
                    style={{
                      background:
                        "linear-gradient(to right, rgba(239, 147, 147, 1), rgba(239, 147, 147, 0.1))",
                    }}
                    className="py-2 px-8 rounded-full  text-white2 text-xl"
                  >
                    Image IA
                  </p>
                </div>
                <h2 className="text-white2 text-4xl sm:text-5xl lg:text-[4vw] font-bold w-[80%] lg:w-full leading-tight">
                  Génération d'images <br />
                  par{" "}
                  <span className="text-[#EF9393]">
                    Intelligence Artificielle
                  </span>
                </h2>
                <div className="flex w-[80%] lg:w-full justify-start">
                  <p className="text-white2 text-2xl lg:w-[75%]">
                    Transformez vos descriptions en visuels époustouflants grâce
                    à notre IA avancée. Que ce soit pour un design graphique,
                    une illustration ou une simple esquisse, notre générateur
                    vous fournit des images de haute qualité en quelques
                    secondes. Libérez votre créativité et laissez l'IA faire le
                    reste.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-col flex sm:flex-row w-full justify-center">
              <div className="h-full w-full flex justify-center lg:w-full relative ">
                <div className="z-10 rounded-3xl w-[80%] h-full lg:w-full relative lg:absolute bg-[#EF9393] bottom-3 left-3 flex items-end shadow-2xl">
                  <Image
                    width={1600}
                    height={900}
                    className="h-full w-full object-cover  rounded-3xl shadow-2xl  object-center "
                    src="/img/landing/ImagiaShot.webp"
                    alt="Image ia screenshot"
                  />
                </div>
                <div className="backdrop-blur-md bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] w-[80%] lg:w-full h-full rounded-3xl absolute top-3 right-3"></div>
              </div>
            </div>
          </InViewDiv>
        </section>
        <section
          className="w-full h-auto xl:h-full flex flex-col items-start md:justify-center relative  my-20 md:mt-0"
          id="price"
        >
          <div className="flex h-1/3 w-full md:flex-col">
            <InViewDiv className="flex w-full justify-center items-center flex-col gap-2 py-10">
              <h2
                style={{
                  background:
                    "linear-gradient(to right, rgb(255 255 255 / 100%), rgb(255 255 255 / 70%))",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="text-4xl md:text-6xl flex justify-center text-center font-semibold"
              >
                Trouvez le plan qui répond
              </h2>
              <h2
                style={{
                  background:
                    "linear-gradient(to right, rgb(255 255 255 / 100%), rgb(255 255 255 / 70%))",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="text-4xl md:text-6xl flex justify-center text-center font-semibold"
              >
                {" "}
                à vos besoins.
              </h2>
              <p className="text-xl text-slate-200 text-center">
                Votre chemin vers la productivité commence avec le bon plan.
              </p>
            </InViewDiv>
          </div>
          <div className="h-full w-full justify-center items-center flex-col flex md:flex-row">
            <div className="h-full w-[90%] max-w-[1350px] xl:justify-center items-center xl:items-center flex-col flex xl:flex-row gap-8">
              <InViewDiv className="min-w-[350px] max-w-[450px]  px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-white/10 w-full md:w-1/3">
                <div className="w-full flex justify-start">
                  <p className="text-white2 px-8 py-1 rounded-full bg-white/10 border-white border">
                    Basique
                  </p>
                </div>
                <div className="justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-8">
                  <div className="flex items-end gap-4">
                    <p className="text-white text-5xl font-bold">19,90 €</p>
                    <p className="text-slate-200">/ mois</p>
                  </div>
                  <p className="text-slate-200 text-xl">
                    La meilleure option pour démarrez votre aventure avec
                    l&apos;IA
                  </p>
                </div>
                <ul className="pt-8 flex flex-col gap-4">
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Tous les outils IA</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>100 000 crédits</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Support client standard</p>
                  </li>
                </ul>
                <div className="w-full flex justify-center items-center pt-8">
                  <button
                    onClick={() => {
                      setToggleSignForm(true);
                      setSignValue("signup");
                    }}
                    className="bg-white/10 py-2 px-8 rounded-md border border-white font-bold hover:bg-white/20 hover:shadow-xl transition"
                  >
                    Essayer Gratuitement
                  </button>
                </div>
              </InViewDiv>

              <InViewDiv className="min-w-[350px] max-w-[450px] px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-[#FFCF54] w-full md:w-1/3 relative">
                <div className="absolute -top-6 px-4 flex justify-center items-center bg-[#FFCF54] text-white font-medium rounded-t-xl">
                  <p>Le + populaire</p>
                </div>
                <div className="w-full flex justify-start">
                  <p className="text-white2 px-8 py-1 rounded-full bg-[#ffcf5433] border-[#FFCF54] border">
                    Premium
                  </p>
                </div>
                <div className="justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-8">
                  <div className="flex items-end gap-4">
                    <p className="text-white text-5xl font-bold">29,90 €</p>
                    <p className="text-slate-200">/ mois</p>
                  </div>
                  <p className="text-slate-200 text-xl">
                    L&apos;option la plus populaire pour débloquez votre
                    potentiel avec l&apos;IA.
                  </p>
                </div>
                <ul className="pt-8 flex flex-col gap-4">
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#FFCF54"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Nombre de mots IA illimité*</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#FFCF54"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Tous les outils IA</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#FFCF54"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>200 000 crédits</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#FFCF54"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Support client prioritaire</p>
                  </li>
                </ul>
                <div className="w-full flex justify-center items-center pt-8">
                  <button
                    onClick={() => {
                      setToggleSignForm(true);
                      setSignValue("signup");
                    }}
                    className="bg-white/10 py-2 px-8 rounded-md border border-[#FFCF54] font-bold hover:bg-white/20 hover:shadow-xl transition"
                  >
                    Essayer Gratuitement
                  </button>
                </div>
              </InViewDiv>

              <InViewDiv className=" lg:h-[517px] justify-center items-center min-w-[350px] max-w-[450px] px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-white/10 w-full md:w-1/3">
                <div className="w-full flex justify-start">
                  <p className="text-white2 px-8 py-1 rounded-full bg-[#43ADD0] border-[#43ADD0] border">
                    Entreprise
                  </p>
                </div>
                <div className="justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-4">
                  <div className="flex items-end gap-4">
                    <p className="text-white text-5xl font-bold">Custom</p>
                    <p className="text-slate-200">/ mois</p>
                  </div>
                  <p className="text-slate-200 text-xl">
                    Optimisez votre entreprise avec un plan sur mesure conçu
                    pour répondre à vos besoins spécifiques.
                  </p>
                </div>
                <ul className="pt-8 flex flex-col gap-4">
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#43ADD0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Tous les outils IA</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#43ADD0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Crédits à la demande</p>
                  </li>
                  <li className="flex gap-4">
                    <svg
                      width={26}
                      height={26}
                      fill="none"
                      stroke="#43ADD0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                    <p>Support client prioritaire</p>
                  </li>
                </ul>
                <div className="w-full flex justify-center items-center pt-8">
                  <Link
                    href="/contact"
                    className="bg-white/10 py-2 px-8 rounded-md border border-[#43ADD0] font-bold hover:bg-white/20 hover:shadow-xl transition"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </InViewDiv>
            </div>
          </div>
        </section>
        <section
          className="w-full flex flex-col justify-center items-center h-full"
          id="about"
        >
          <div className="flex h-1/3 w-full md:flex-col">
            <InViewDiv className="flex w-full justify-center items-center flex-col gap-2 py-10 px-4">
              <h2
                style={{
                  background:
                    "linear-gradient(to right, rgb(255 255 255 / 100%), rgb(255 255 255 / 70%))",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="text-3xl sm:text-4xl md:text-6xl flex justify-center text-center font-semibold"
              >
                A propos d’EurekAI.
              </h2>
              <p className="text-md sm:text-xl text-slate-200 text-center max-w-[450px]">
                Démocratiser l'intelligence artificielle. Offrir des outils
                puissants et accessibles. C'est notre engagement, et nous le
                vivons chaque jour.
              </p>
            </InViewDiv>
          </div>
          <div className=" w-[70%] justify-center md:grid-cols-2 grid-cols-1 grid gap-20 sm:pl-20">
            <InViewDiv className="flex flex-col gap-4 px-4 border-l border-[#E17DC2]">
              <h3 className="flex flex-col">
                <span className="text-[#E17DC2] font-bold text-4xl">
                  Fonctionnalités
                </span>
                <span className="text-[#E17DC2] font-bold text-4xl">
                  évolutives.
                </span>
              </h3>
              <p className="text-xl text-slate-200 text-left max-w-[450px]">
                Nous ne nous contentons jamais. Des mises à jour régulières pour
                vous offrir l'IA la plus avancée, la plus efficace, toujours.
              </p>
            </InViewDiv>
            <InViewDiv className="flex flex-col gap-4 px-4 border-l border-[#998EE0]">
              <h3 className="flex flex-col text-[#998EE0] font-bold text-4xl">
                <span>L'excellence en</span>
                <span>continu.</span>
              </h3>
              <p className="text-xl text-slate-200 text-left max-w-[450px]">
                Nous écoutons. Nous apprenons. Nous introduisons des
                fonctionnalités qui répondent à VOS besoins. Vous guidez notre
                chemin.
              </p>
            </InViewDiv>
            <InViewDiv className="flex flex-col gap-4 px-4 border-l border-[#43ADD0]">
              <h3 className="flex flex-col text-[#43ADD0] font-bold text-4xl">
                <span>Rejoignez</span>
                <span>l'Aventure IA.</span>
              </h3>
              <p className="text-xl text-slate-200 text-left max-w-[450px]">
                Explorez, créez, innovez. Avec notre plateforme, vos idées
                prennent vie. Nous sommes là pour vous, à chaque étape.
              </p>
            </InViewDiv>
            <div></div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}
