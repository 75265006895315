import React, { useState, useEffect } from 'react';

const messages = [
    "Vos textes rédigés en secondes, pas en heures.",
    "Des IA consultants spécialisés à votre portée.",
    "Discutez directement avec vos PDF."
];


const RotatingText: React.FC = () => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [textToShow, setTextToShow] = useState("");

    useEffect(() => {
        let charIndex = 0;
        const currentMessage = messages[currentMessageIndex];

        const typeEffect = setInterval(() => {
            if (charIndex < currentMessage.length) {
                let index: number;
                if (currentMessage[charIndex - 1] !== undefined) {
                    index = charIndex
                } else {
                    index = 0
                }
                setTextToShow((prev) => prev + currentMessage[index]);
                charIndex++;
            } else {
                clearInterval(typeEffect);
                setTimeout(() => {
                    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                    setTextToShow(""); // Réinitialisez le texte à afficher avant de passer au prochain message
                }, 2000);
            }
        }, 50);

        return () => clearInterval(typeEffect);
    }, [currentMessageIndex]);

    return <div className="rotating-text text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-medium min-h-[120px] sm:min-h-[180px] md:min-h-[200px] h-auto">{textToShow}</div>;
};

export default RotatingText;