import React, { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

interface InViewDivProps {
  children: ReactNode;
  className?: string;
  id?: string;
}

const InViewDiv: React.FC<InViewDivProps> = ({ children, className = '', id }) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const divProps = id ? { id } : {};

  return (
    <div
      ref={inViewRef}
      {...divProps}
      className={`transition-opacity duration-1000 ${inView ? 'opacity-100' : 'opacity-0'} ${className}`}
    >
      {children}
    </div>
  );
};

export default InViewDiv;
